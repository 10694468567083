<!--
 * @Author: wxb
 * @Date: 2022-03-22 15:11:32
 * @LastEditors: wxb
 * @LastEditTime: 2022-04-29 11:30:53
 * @Description:
-->
<template>
  <div class="containner">
    <headerNav :activeIdx='9'></headerNav>
    <div class="content">
      <el-breadcrumb separator="/">
        <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
        <el-breadcrumb-item>明星企业</el-breadcrumb-item>
      </el-breadcrumb>
      <div class="search-info">
        <div class="project-search">
          <div class="project-search-input">
            <el-input class="project-search-field"
                      v-model="searchForm.keyword"
                      placeholder="输入关键词搜索..."
                      clearable
                      @keyup.enter.native="searchCompany"></el-input>
            <button type="button"
                    class="project-search-btn"
                    @click="searchCompany">搜索</button>
          </div>
        </div>
        <div class="search-senior">
          <button @click="showSenior=!showSenior">筛选 <i class="el-icon-arrow-up"
               v-show="showSenior"></i>
            <i class="el-icon-arrow-down"
               v-show="!showSenior"></i>
          </button>
        </div>
      </div>
      <div class="project-search-senior"
           v-show="showSenior">
        <div class="project-search-senior-item">
          <div class="project-search-senior-item-title">企业注册地：</div>
          <div class="project-search-senior-item-content">
            <div class="area-content"
                 :class="{autoH:autoH.area}">
              <div class="area-content-item"
                   :class="index===activeArea ? 'active' : ''"
                   v-for="(item,index) in province"
                   :key="index"
                   @click="handleClick4Area(index)">{{item}}</div>
            </div>
            <div class="showmore-tools"
                 @click="autoH.area = !autoH.area">
              <span v-show="!autoH.area"> 更多<i class="el-icon-arrow-down"></i></span>
              <span v-show="autoH.area"> 收起<i class="el-icon-arrow-up"></i></span>
            </div>
          </div>
        </div>
      </div>
      <div class="result-count">
        符合条件的机构共{{companyCnt}}家
      </div>
      <div v-show="!noData">
        <div class="company-list">
          <div class="company-item"
               @click="handleClickGoDetail(item.enterpriseId)"
               v-for="(item,index) in companyList"
               :key="index">
            <div class="company-item-logo">
              <img class="logo"
                   :src="item.logo && item.logo.path ? `/api/company/fileManagementNoLogin/preview?fileName=${item.logo.name}&filePath=${item.logo.path}`: nologo" />
            </div>
            <div class="company-item-content">
              <div class="company-title">
                <div class="company-name">{{item.enterpriseName}}</div>
                <div class="company-main-business"
                     v-if="item.mainBusiness">主营：{{item.mainBusiness}}</div>
              </div>
              <div class="company-survey">{{item.survey}}</div>
              <div class="company-address">{{item.diZh}}</div>
            </div>
          </div>
        </div>
        <div class="content-page">
          <el-pagination background
                         layout="prev, slot, next"
                         :total="companyCnt"
                         :current-page.sync="companyCurr"
                         :page-size="20"
                         @current-change="handleCurrentChange">
            <span class="current-page">{{companyCurr}}</span>
          </el-pagination>
        </div>
      </div>
      <div v-show="noData">
        <el-empty description="未查询到相关数据"></el-empty>
      </div>

    </div>
    <footerNav></footerNav>
  </div>
</template>

<script>
import headerNav from '@/components/headerNavBlue'
import footerNav from '@/components/footerNav'
import { getStarEnterpriseList } from '@/api/startCompany/index'
import nologo from '@/assets/no-logo.png'
export default {
  name: 'starCompany',
  components: {
    headerNav,
    footerNav
  },
  data() {
    return {
      searchForm: {
        keyword: '',
        zhcsd: '',
        start: 0,
        limit: 20
      },
      showSenior: true,
      province: ['全国', '北京市', '天津市', '河北省', '山西省', '内蒙古自治区', '辽宁省', '吉林省', '黑龙江省', '上海市', '江苏省', '安徽省', '福建省', '江西省', '山东省', '河南省', '湖北省', '湖南省', '', '广东省', '广西壮族自治区', '海南省', '重庆市', '四川省', '贵州省', '云南省', '西藏自治区', '陕西省', '甘肃省', '青海省', '宁夏回族自治区', '新疆维吾尔自治区', '香港特别行政区', '澳门特别行政区', '台湾省'],
      autoH: {
        area: true
      },
      activeArea: 0,
      companyList: [],
      companyCnt: 0,
      companyCurr: 1,
      nologo,
      noData: false
    }
  },
  computed: {
    noMore() {
      return this.count >= 20
    },
    disabled() {
      return this.loading || this.noMore
    }
  },
  methods: {
    // 搜索明星企业
    searchCompany() {
      this.searchForm.start = 0
      this.companyCurr = 1
      this.getList()
    },
    // 地区点击
    handleClick4Area(index) {
      this.activeArea = index
      const proivnce = this.province[index]
      if (proivnce === '全国') {
        this.searchForm.zhcsd = ''
      } else {
        this.searchForm.zhcsd = proivnce
      }
      this.searchCompany()
    },
    handleClickGoDetail(id) {
      const routeData = this.$router.resolve({
        name: 'starCompanyDetail',
        params: { id, nav: 0 }
      })
      window.open(routeData.href, '_blank')
    },
    // 处理分页
    handleCurrentChange(val) {
      this.searchForm.start = (val - 1) * this.searchForm.limit
      this.getCompanyList()
    },
    getList() {
      getStarEnterpriseList(this.searchForm).then(res => {
        console.log(res)
        if (res.ok) {
          this.companyCnt = res.cnt
          this.companyList = res.lst
          if (res.cnt > 0) {
            this.noData = false
          } else {
            this.noData = true
          }
        }
      }).catch(err => {
        console.log(err)
      })
    }
  },
  mounted() {
    this.getList()
  }
}
</script>

<style lang="scss" scoped>
.content {
  width: 144rem;
  margin: 0 auto;
}

.el-breadcrumb {
  height: 5.4rem;
  line-height: 5.4rem;
}

// 搜索
.search-info {
  margin: 0 auto;
  width: 700px;
  display: flex;

  .search-senior {
    flex: 1;
    margin-left: 20px;
    button {
      width: 80px;
      height: 40px;
      background-color: #398ce2;
      border: 1px solid #398ce2;
      font-size: 14px;
      letter-spacing: 0px;
      color: #ffffff;
      cursor: pointer;
    }
  }
}
// 搜索框
.project {
  &-search {
    background: #ffffff;
    &-input {
      width: 660px;
      height: 40px;
      display: flex;
      margin: 0 auto;
    }

    &-field {
      flex: 1;
      border: solid 2px #398ce2;
      background-color: #ffffff;
      font-size: 14px;
      font-weight: normal;
      letter-spacing: 0px;
      color: #898a8c;
      /deep/ .el-input__inner {
        border-radius: 0;
        height: 36px;
        line-height: 36px;
      }
    }

    &-btn {
      right: 0;
      width: 56px;
      height: 40px;
      background-color: #398ce2;
      border: 1px solid #398ce2;
      font-size: 14px;
      font-weight: 600;
      letter-spacing: 0px;
      color: #ffffff;
      cursor: pointer;
    }
  }
}

// 筛选信息
.project {
  &-search-senior {
    background: #ffffff;
    margin: 10px auto;
    padding: 5px 10px;
    &-item {
      display: flex;
      font-size: 14px;
      padding: 18px 0px 10px;
      &:not(:last-child) {
        border-bottom: 1px dashed #e0e0e0;
      }
      &-title {
        width: 100px;
        color: #686868;
        text-align: right;
        padding: 4px 15px 4px 0px;
      }
      &-content {
        flex: 1;
        display: flex;
        .autoH {
          height: auto !important;
        }

        // 更多操作
        .showmore-tools {
          width: 60px;
          padding: 4px 7px;
          cursor: pointer;
          color: #686868;
        }

        .area-content {
          flex: 1;
          display: flex;
          flex-wrap: wrap;
          height: 30px;
          overflow: hidden;
          &-item {
            padding: 4px 7px;
            cursor: pointer;
            margin: 0 2px 10px 0;
            color: #398ce2;
          }
          .active {
            background-color: #398ce2;
            color: #ffffff;
            border-radius: 2px;
          }
        }
      }
    }
  }
}

.result-count {
  background: #f1f1f1;
  padding: 10px;
}

.company {
  &-list {
    padding-top: 10px;
    padding-bottom: 10px;
    cursor: pointer;
  }

  &-item {
    position: relative;
    width: 100%;
    height: 158px;
    display: flex;
    padding-bottom: 10px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.05);
    &-content {
      flex: 1;
      padding-left: 10px;
    }

    &:not(:last-child) {
      margin-bottom: 10px;
    }

    &-logo {
      width: 240px;
      background: #ffffff;
      box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.2);
      display: flex;
      align-items: center;
      justify-content: center;

      .logo {
        width: auto;
        height: 88px;
      }
    }
  }

  &-title {
    box-sizing: border-box;
    font-size: 18px;
    color: rgba(0, 0, 0, 0.85);
    line-height: 32px;
    font-weight: 500;
    display: flex;
    justify-content: space-between;

    .company-main-business {
      font-size: 22px;
      color: #398ce2;
      font-weight: 500;
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 1;
      max-width: 612px;
    }
  }

  &-survey {
    font-size: 16px;
    color: rgba(0, 0, 0, 0.65);
    line-height: 26px;
    font-weight: 500;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 3;
    height: 78px;
    white-space: pre-line;
  }

  &-address {
    font-size: 14px;
    color: rgba(0, 0, 0, 0.45);
    line-height: 40px;
    font-weight: 400;
  }
}

.content-page {
  padding: 10px 0;
  text-align: right;
}
</style>
